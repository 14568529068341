import { Injectable } from '@angular/core';
import { Observable, catchError, map, throwError } from 'rxjs';
import { IPaginationResponse, IRestResponse } from '../core/interface/response.interface';
import { RestService } from '../core/services/rest.service';
import { environment } from '../../environments/environment';
import { IService, IUserResponse } from '../interfaces/user.interface';
import {ISearchResult } from '../interfaces/search-result.interface';
import { ISearch } from '../core/interface/search.interface';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(private restService: RestService) { }

  public searchUserList( payload:ISearch): Observable<IRestResponse<ISearchResult<IUserResponse>>> {
    return this.restService
      .post(environment.baseUrl + `/api/v1/employee/search`,payload , false)
      .pipe(
        map((res:any) => res),
        catchError((error) => throwError(error))
      );
  }
  public getUserServices(): Observable<IRestResponse<IService[]>> {
    return this.restService
      .get(environment.baseUrl + `/api/v1/employee/services`,null , false)
      .pipe(
        map((res:any) => res),
        catchError((error) => throwError(error))
      );
  }

  public getUserInformation(userId: string): Observable<IRestResponse<IUserResponse>> {
    return this.restService
      .get(environment.baseUrl + `/api/v1/employee/${userId}`,null , false)
      .pipe(
        map((res:any) => res),
        catchError((error) => throwError(error))
      );
  }
  public updateProfilePic(userId: string, data: any): Observable<IRestResponse<IUserResponse>> {
    return this.restService
      .put(environment.baseUrl + `/api/v1/employee/profilePic/${userId}`, data, false, true)
      .pipe(
        map((res:any) => res),
        catchError((error) => throwError(error))
      );
  }
  public setUserRolesAndService(id: string, payload: any): Observable<IRestResponse<IService[]>> {
    return this.restService
      .put(environment.baseUrl + `/api/v1/employee/${id}`,payload , false)
      .pipe(
        map((res:any) => res),
        catchError((error) => throwError(error))
      );
  }
}